import React from 'react';

import styled from '@emotion/styled';
import { useTranslation } from 'next-i18next';

import OfflineIcon from '@assets/Icons/Offline.svg';
import { useOnlineStatus } from '@hooks';
import getAlphaHex from '@utils/getAlphaHex';

import Sidebar from '../modules/Sidebar';

const AppLayoutContainer = styled.div`
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: row;
  background-color: ${(props) => props.theme.color.primary100};
`;

const StyledSection = styled.section`
  height: 100%;
  width: calc(100vw - 4.5em);
  position: relative;
  overflow-y: auto;
`;

const StyledOfflineOverlay = styled.div`
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100vh;
  background: ${(props) => getAlphaHex(props.theme.color.primary900, 45)};

  .status-bar {
    width: fit-content;
    background: ${(props) => props.theme.color.primary500};
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 0.3rem;
    overflow: hidden;
    right: 0;
    left: 0;
    margin: auto;
    margin-top: 3rem;

    .icon-container {
      display: flex;
      padding: 1rem 2rem;
      background: ${(props) => props.theme.color.primary200};
      border-right: 2px solid ${(props) => props.theme.color.primary50};

      svg {
        fill: ${(props) => props.theme.color.danger300};
        width: 2rem;
      }
    }
    .message {
      padding: 1rem;
      padding-right: 2rem;
      color: ${(props) => props.theme.color.gray100};
      text-align: center;
      font-size: 1.1em;
    }
  }
`;

interface Tab {
  id: string;
  title: string;
  icon: JSX.Element;
  onClick(): any;
}
interface SidebarProps {
  logo: JSX.Element;
  appIcon?: JSX.Element;
  tabs: Tab[];
}

interface Props {
  // title?: string;
  children: JSX.Element;
  sidebarProps: SidebarProps;
  className?: string;
}

// Global app layout for apps
function AppLayout(props: Props): JSX.Element {
  // Get network status of user - checks if the user is connected to the internet
  const isOnline = useOnlineStatus();
  const { t: common } = useTranslation();
  return (
    // App container
    <AppLayoutContainer>
      {/* Sidebar */}
      <Sidebar sidebarProps={props.sidebarProps} />

      {/* Remainder of the page */}
      <StyledSection>
        {/* If the user is offline, display an overlay with the Offline warning  */}
        {!isOnline && (
          <StyledOfflineOverlay>
            <div className="status-bar">
              <div className="icon-container">
                <OfflineIcon />
              </div>
              <div className="message">{common('warnings.noInternet')}</div>
            </div>
          </StyledOfflineOverlay>
        )}

        {/* Children - page components */}
        {props.children}
      </StyledSection>
    </AppLayoutContainer>
  );
}

export default AppLayout;
