import React from 'react';

import styled from '@emotion/styled';
import { Drawer } from 'antd';

const StyledDrawer = styled(Drawer)`
  .ant-drawer-content-wrapper {
    // height: calc(100vh - 5em) !important;
    bottom: 0;
    // min-width: 20em;
  }
  .ant-drawer-content {
    background-color: ${(props) => props.theme.sideDrawer.bgColor};
    // height: calc(100vh - 5em);
  }
  .ant-drawer-body {
    padding: 0;
    display: flex;
    flex-direction: column;
  }
  .menuitem {
    margin-bottom: 0;
    padding: 0.75em 0 0.75em 0.5em;
    cursor: pointer;
  }
  .menuitem:hover {
    background-color: ${(props) => props.theme.profilePanel.hoverColor};
  }
  .menuitem:active {
    background-color: ${(props) => props.theme.profilePanel.activeColor};
  }
  .menuitem-heading {
    font-size: 1.25em;
    color: ${(props) => props.theme.profilePanel.textHeading};
  }
  .menuitem-desc {
    font-size: 1em;
    color: ${(props) => props.theme.profilePanel.textDesc};
  }
  .menuitem-icon {
    color: ${(props) => props.theme.profilePanel.iconColor};
    width: 2.25em;
    height: 2.25em;
  }
`;

interface SideDrawerProps {
  children?: React.ReactNode;
  closable?: boolean;
  className?: string;
  placement: 'right' | 'left';
  onDrawerClose(): void;
  isDrawerOpen: boolean;
  toggleTheme?(): void;
  isDarkTheme?: boolean;
  width?: number;
}

const SideDrawer = (props: SideDrawerProps): JSX.Element => {
  return (
    <div className={props.className}>
      <StyledDrawer
        placement={props.placement}
        closable={props.closable}
        onClose={props.onDrawerClose}
        open={props.isDrawerOpen}
        width={props.width || 350}
      >
        {props.children}
      </StyledDrawer>
    </div>
  );
};

export default SideDrawer;
