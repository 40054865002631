import axios from 'axios';

const logout = async () => {
  const NEXT_PUBLIC_BACKEND_HTTP_URL = process.env
    .NEXT_PUBLIC_BACKEND_HTTP_URL as string;

  await axios.get(NEXT_PUBLIC_BACKEND_HTTP_URL + '/dashboard/auth/v1/logout', {
    withCredentials: true,
  });

  // Connection to a broadcast channel
  const bc = new BroadcastChannel('auth_channel');
  // Clear access token for all active tabs in the browser
  bc?.postMessage({
    accessToken: '',
    tokenExpiry: null,
  });

  window.location.href = '/login';
};

export default logout;
